import React, {Component, Fragment} from 'react'
import {postChat, getChat, postMessage} from '../../api/chat'
import styled, {keyframes} from 'styled-components'
import socket from "../../api/socket"
import Scrollbars from 'react-custom-scrollbars'
import {ReactComponent as Send} from '../../images/send.svg'
import {ReactComponent as Digitalk} from '../../images/digitalk.svg'
import classNames from 'classnames'
import LogoDigitalk from '../../images/logo.png'
import Logo from '../../images/logo.png'
import {ReactComponent as Clip} from '../../images/clip.svg'
import {ReactComponent as Trash} from '../../images/trash.svg'
import {ReactComponent as Download} from '../../images/download.svg'

const Anim = keyframes`
    0% {
        transform: rotate(0);
        width: 200px;
        height: 200px;
    }
   25% {
    
        width: 150px;
        height: 150px;
    
  }
  50% {
    
        width: 200px;
        height: 200px;
    
  }
  75% {
   
        width: 150px;
        height: 150px;
    
  }
  100% {
     transform: rotate(360deg);
        width: 200px;
        height: 200px;
    
  }
`
const Button = styled.button`
    display: flex;
    color: #FFFFFF;
    background: #ad3edb;
    border-radius: 25px;
    font-weight: bold;
    font-size: .875rem;
    padding: .8rem 1.1rem;
    border: 0;
    align-items: center;
`
const Load = styled.div`
    background-color: #ad3edb;
    position:absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    img {
        height: 40%;
    }
    .powered {
        display: flex;
        color: #fff;
        font-size: 14px;
        padding: 32px;
        flex-direction: column;
        align-items: center;
        p {
            margin-bottom: 5px;
        }
    }
`
const FilesContainer = styled.div`
    color: ${props => props.theme.bg};
    display: flex;
    padding: 0 1rem;
    flex-wrap: wrap;
    >div {
        display: flex;
        flex-direction: column;
        width: 7.5rem;
        margin-right: 1rem;
        margin-top: .5rem;
        >div {
            &:first-child{
                background: rgba(77,188,172,0.5);
                color: ${props => props.theme.bg};
                height: 40px;
                font-size: 1.2rem;
                justify-content: center;
                align-items: center;
                display: flex;
            }
            &:last-child{
                background-color: ${props => props.theme.bg};
                color: ${props => props.theme.text};
                display: flex;
                padding: .35rem;
                justify-content: space-between;
                > p {
                    max-width: 80%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                svg {
                    width: 1rem;
                    height: 1rem;
                    cursor: pointer;
                    stroke: ${props => props.theme.text};
                }
            }
        }
    }
`

const InputFile = styled.input`
    display: none;
`
const MessageForm = styled.form`
    display: flex;
    padding: .75rem .875rem;
    max-height: 65px;
    input {
        font-size: .875rem;
        padding: 8px 15px;
        width: 100%;
        border: 1px solid #E0E0E0;
        border-radius: 5px;
        &:focus {
            outline: none;
        }
    }
    >svg {
        height: 16px;
        cursor: pointer;
        margin: 12px 10px 12px 0;
    }
    ${Button} {
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        height: 41px;
        padding: 9px 15px;
        justify-content: center;
        margin-left: .6rem;
        background: #ad3edb;
        svg {
            width: .9rem;
        }
        &:disabled {
            background-color: #989898;
            cursor: auto;
        }
    }
`
const MessageContainer = styled.div`
    height: calc(100% - 65px);
`
const ChatContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    >div {
        display: flex;
        flex-direction: column;
    }
    h4 {
        display: flex;
        justify-content: center;
        color: #FFF;
        background: #ad3edb;
        font-size: 1.3rem;
        padding: 0 32px;
        > img {
            height: 80px;
        }
        &+ div {
            background: #FFF;
            width: 100%;
            height: calc(100% - 80px);
        }
    }
`

const Messages = styled.div`
    padding: .5rem 2rem;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    width: 100%;
    height: fit-content;
    > div {
        width: fit-content;
        max-width: 80%;
        padding: 1rem;
        font-size: .9rem;
        color: #333333;
        border-radius: 5px;
        &+div {
            margin-top: .6rem;
        }
        &.operator {
            background: #e7e7e7;
            &+.operator {
                margin-top: .3rem;
            }
        }
        &.customer{   
            box-shadow: 0 0 10px rgba(171, 94, 203, 0.15);
            align-self: flex-end;
            background: #e4e9ef;
            &+.customer {
                margin-top: .3rem;
            }
        }
    }
`
const Scrollbar = styled(Scrollbars)`
    width: 100%;
    height: 100%;
    >div{
        &:nth-child(3){
            opacity: 1!important;
            display: block!important;
        }
    }`
const FileContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 6.5rem;
    margin-top: .5rem;
    flex-wrap: wrap;
    padding: 0 0 1rem!important;
    >div {
        max-width: 6.5rem;
        &:first-child{
            background: rgba(77,188,172,0.5);
            color: ${props => props.theme.bg};
            height: 40px;
            font-size: 1.2rem;
            justify-content: center;
            
            align-items: center;
            display: flex;
        }
        &:last-child{
            background-color: ${props => props.theme.bg};
            color: ${props => props.theme.text};
            display: flex;
            padding: .35rem;
            justify-content: space-between;
            > p {
                max-width: 80%;
                overflow: hidden;
                font-size: .9rem;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            svg {
                width: 1rem;
                height: 1rem;
                cursor: pointer;
                stroke: ${props => props.theme.text};
            }
        }
    }
`

function msToTime(duration) {
    let hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
        days = Math.floor((duration / (1000 * 60 * 60 * 24)))

    return {
        days,
        hours,
    }
}

class Widget extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chatMessage: '',
            filesMessage: [],
            chatMessages: [],
            chatLoad: true
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmitChat = this.handleSubmitChat.bind(this)
        this.handleSend = this.handleSend.bind(this)
        this.handleSendFile = this.handleSendFile.bind(this)
    }

    componentDidMount() {
        let chat = JSON.parse(localStorage.getItem('chat'))
        let diff = chat && msToTime(Math.abs(new Date(chat.createdAt) - new Date()))
        setTimeout(() => {
            if (chat && diff && (diff.days === 0 || diff.hours < 3)) {
                getChat(chat.protocol).then(({data}) => data.closed ? this.handleSubmitChat() : this.setState({
                    chatLoad: false,
                    chatId: data.id,
                    chatMessages: data.messages.map(x => ({
                        message: x.body,
                        from_contact: x.source === "customer", ...x
                    }))
                }, () => {
                    socket.emit('subscribe', this.state.chatId)
                    localStorage.setItem('chat', JSON.stringify({
                        protocol: data.id,
                        createdAt: new Date()
                    }   ));
                    if (this.messages.lastElementChild) {
                        this.messages.lastElementChild.scrollIntoView()
                    }
                })).catch(err => console.log(err))
            } else {
                this.handleSubmitChat()
            }
        }, 1500)


        socket.on('addMessage', (data) => {
            this.setState(prevState => ({chatMessages: [...prevState.chatMessages, {...data, from_contact: false}]}))
            if (this.messages.lastElementChild) {
                this.messages.lastElementChild.scrollIntoView()
            }
        })

        socket.on('terminate', () => {
            setTimeout(() => {
                this.handleSubmitChat()
            }, 5000)
        })
    }

    handleSend(e) {
        e.preventDefault()
        let message = e.target.chatMessage
        const {chatId, filesMessage} = this.state

        if (message.value !== '' || filesMessage.length > 0) {
            if (filesMessage.length > 0) {
                filesMessage.map(x => {
                    let formData = new FormData()
                    formData.append('chatId', chatId)
                    formData.append('message', '')
                    formData.append('files[]', x)
                    formData.append('type', filesMessage.length === 0 ? 'text' : 'media')
                    postMessage(formData).then(res => {
                        let data = res.data[0]
                        this.setState(prevState => ({
                            chatMessage: '',
                            filesMessage: [],
                            chatMessages: [...prevState.chatMessages, {message: data.body, type: data.type,file: data.file, from_contact: true}]
                        }))
                        if (this.messages.lastElementChild) {
                            this.messages.lastElementChild.scrollIntoView()
                        }
                    }).catch(err => console.log(err))
                })

                if (message.value !== '') {
                    let formDataM = new FormData()
                    formDataM.append('chatId', chatId)
                    formDataM.append('message', message.value)
                    formDataM.append('type', 'text')
                    postMessage(formDataM).then(res => {
                        let data = res.data[0]
                        this.setState(prevState => ({
                            chatMessage: '',
                            filesMessage: [],
                            chatMessages: [...prevState.chatMessages, {message: data.body, type: data.type, file: data.file, from_contact: true}]
                        }))
                        if (this.messages.lastElementChild) {
                            this.messages.lastElementChild.scrollIntoView()
                        }
                    }).catch(err => console.log(err))
                }
            }else {
                let formData = new FormData()
                formData.append('chatId', chatId)
                formData.append('message', message.value)
                formData.append('type', filesMessage.length === 0 ? 'text' : 'media')
                postMessage(formData).then(res => {
                    let data = res.data[0]
                    this.setState(prevState => ({
                        chatMessage: '',
                        filesMessage: [],
                        chatMessages: [...prevState.chatMessages, {message: data.body, file: data.file,type: data.type, from_contact: true}]
                    }))
                    if (this.messages.lastElementChild) {
                        this.messages.lastElementChild.scrollIntoView()
                    }
                }).catch(err => console.log(err))
            }

        }
    }

    handleSubmitChat() {
        localStorage.clear()

        postChat().then(res => this.setState({chatLoad: false, ...res.data}, () => {
            socket.emit('subscribe', this.state.chatId)
            getChat(this.state.chatId).then(({data}) => {
                this.setState({
                    chatLoad: false,
                    chatId: data.id,
                    chatMessages: data.messages.map(x => ({
                        message: x.body,
                        from_contact: x.source === "customer", ...x
                    }))
                })
            }).catch(err => console.log(err))
            localStorage.setItem('chat', JSON.stringify({
                protocol: this.state.chatId,
                createdAt: new Date()
            }))
        })).catch(err => console.log(err))
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSendFile(e) {
        const file = [...e.target.files]
        this.setState(prevState => ({filesMessage: [...prevState.filesMessage, ...file]}))

        e.target.value = ""
    }

    render() {
        const {chatLoad, chatMessages, chatMessage, filesMessage} = this.state
        return chatLoad ? <Load className={classNames({load: chatLoad})}>
            <div></div>
            <img src={LogoDigitalk} alt="Logo Digitalk"/>
            <div className='powered'>
                <p>Powered by</p>
                <Digitalk/>
            </div>
        </Load> : <ChatContainer>
            <h4 className='title'><img src={Logo} alt="Arklok"/></h4>
            <div>
                <MessageContainer>
                    <Scrollbar>
                        <Messages ref={e => this.messages = e}>
                            {chatMessages.map((x, i) => {
                                x.message = x.message.replace(/\n/g, "<br />")
                                return x.type === 'media' ? <FileContainer className={classNames({
                                    operator: !x.from_contact,
                                    customer: x.from_contact,
                                })}>
                                    <div>{x.file ? x.file.split('/')[x.file.split('/').length - 1].split('.')[x.file.split('/')[x.file.split('/').length - 1].split('.').length - 1] : x.message.split('/')[x.message.split('/').length - 1].split('.')[x.message.split('/')[x.message.split('/').length - 1].split('.').length - 1]}</div>
                                    <div>
                                        <p>{x.file ? x.file.split('/')[x.file.split('/').length - 1] : x.message.split('/')[x.message.split('/').length - 1]}</p>
                                        <a href={x.file ?? x.message} download={true} target="_blank">
                                            <Download/>
                                        </a>
                                    </div>
                                </FileContainer> : <div key={i} className={classNames({
                                    operator: !x.from_contact,
                                    customer: x.from_contact,
                                })} lang='pt-br'><p dangerouslySetInnerHTML={{__html: x.message}}/></div>
                            })}
                        </Messages>
                    </Scrollbar>
                </MessageContainer>
                <FilesContainer>
                    {filesMessage && filesMessage.map((x, index) => <div>
                        <div>{x.name.split('.')[x.name.split('.').length - 1]}</div>
                        <div><p>{x.name}</p> <Trash
                            onClick={() => this.setState(prevState => {
                                let files = prevState.filesMessage
                                files = files.filter((x, i) => i !== index)
                                return {filesMessage: files}
                            })}/></div>
                    </div>)}
                </FilesContainer>
                <MessageForm onSubmit={this.handleSend}>
                    <InputFile type='file' ref={el => this._inputFile = el} name="file" onChange={this.handleSendFile}
                               multiple/>
                    <Clip onClick={() => this._inputFile.click()}/>
                    <input type="text" placeholder='Escreva aqui...' value={chatMessage}
                           onChange={this.handleChange} name='chatMessage'/>
                    <Button disabled={chatMessage.trim() === '' && filesMessage.length === 0}><Send/></Button>
                </MessageForm>
            </div>
        </ChatContainer>
    }
}

export default Widget;
