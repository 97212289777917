import React, {Component} from 'react'
import Widget from './component/widget'
import { createGlobalStyle } from "styled-components"
import { Normalize } from 'styled-normalize'
import ProximaLight from './styles/fonts/ProximaNovaLight.otf'
import ProximaSemi from './styles/fonts/ProximaNovaSemibold.otf'

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "Proxima";
        src: url('${ProximaLight}') format('opentype');
    }
    
    @font-face {
        font-family: "Proxima";
        font-weight: bold;
        src: url('${ProximaSemi}') format('opentype');
    }

    *, *:after, *:before {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    html, body, #root {
        font-family: "Proxima",-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
        width: 100%;
        height: 100%;
        font-size: 100%;
    }
`

function App() {
    return <>
        <GlobalStyle/>
        <Normalize/>
        <Widget/>
    </>
}

export default App