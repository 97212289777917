import instance from './instance'

export function postChat() {
    return instance.get(`/chat/start/`)
}

export function getChat(chatId) {
    return instance.get(`/chat/${chatId}` )
}

export function postMessage(formData) {
    return instance.post(`/messages/send/`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
