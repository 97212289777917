import axios from 'axios'


const instance = axios.create({
    baseURL: 'https://mschat.digitalk.com.br/api/v1',
    headers: {
        'Content-Type': 'application/json'
    }
});

instance.interceptors.request.use (
    config => {
      config.headers.Authorization = "bf73810dafae37731dc9902387eabd0a";
      return config;
    },
    error =>  Promise.reject (error)
)

export default instance